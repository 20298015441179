<template>
<!-- eslint-disable -->
	<div class="login">
		<div class="mt-16">
			<h1 class="text-center" style="color: white; font-weight: 600; font-size: 40px;">OASIS DAILY</h1>
			<v-card
				class="mx-auto my-8"
				max-width="330"
				height="350"
			>
			<h3 class="text-center pt-8">ログイン</h3>
				<v-card-text>
					<v-row>
						<input type="text" class="input pl-4 mt-12" style="border-color: prime!important;" v-model="userId" placeholder="USER ID">
					</v-row>
					<v-row>
						<input type="text" class="input pl-4 mt-6" v-model="password" placeholder="PASSWORD" oncopy="return false" style="-webkit-text-security:disc;">
					</v-row>
				</v-card-text>
				<v-btn
					:loading="loading"
					:disabled="loading"
					color="indigo"
					outlined
					@click="login()"
					style="margin: 45px 120px;"
				>
					Log in
				</v-btn>
			</v-card>
			<v-btn
				color="white"
				outlined
				@click="register()"
				style="position: absolute; right: 10%; bottom: 20%;"
			>
				SIGN UP
			</v-btn>
			<v-footer
				dark
				color="#1976D2"
				min-height="20px"
				width="100%"
				style="position: absolute; bottom: 0;"
			>
				<v-spacer></v-spacer>
				created by takeno
			</v-footer>
		</div>


		<v-dialog
			v-model="popFlg"
			persistent
			max-width="90%"
			>
			<v-card class="gradation">
				<div class="text-center pt-6 pb-4 pl-2" style="white-space: pre-wrap;">
					<h3 v-if="updateMes" class="pb-2">お知らせ</h3>
					<h3 v-if="birthdayMes" class="pb-2">{{ userData.name }}さん　誕生日おめでとう！</h3>
					{{ this.message }}
				</div>
				<v-card-actions>
					<v-btn block tile color="red" dark depressed small @click="togglePop()" class="mb-2">
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>



	</div>
</template>

<script>
/*eslint-disable */
import * as api from '@/api.js'
export default {
	name: 'Login',
	components: {},
	data () {
		return {
			userId: '', // 画面側起動用

			loader: null,
			loading: false,
			// ダイアログ
			popFlg: false,
			errorFlg: false,
			password: '',
			message: '',
			userData: {
				userId: '',
				name: '',
			},
			notiDate: '',


			// 誕生日
			birthdayMes: '',
			// アップデート情報
			updateMes: '',


		}
	},
	methods: {
		load() {
			this.userData.userId = '',
			this.password = ''
		},


		login() {
			this.loader = 'loading'
			api.login.login({userId: this.userId, password: this.password}).then((res) => { 
                switch(res.data.status) {
                    case 200:
						this.userData = res.data.content
						this.$store.dispatch('setLoginInfo', this.userData)
						// アップデート情報
						this.notiDate = this.userData.notification.original.content[0].updated_at.slice(0, 10).replace('-','').replace('-','')

						if(this.userData.birthday) { 
							// 誕生日メッセージ
							this.birthdayMes = this.userData.birthday.original.content
							this.pop(this.birthdayMes)
						}
						else if(Math.abs(this.notiDate - this.getNowYMD()) < 3) {
							this.updateMes = this.userData.notification.original.content[0].content
							this.pop(this.updateMes)
						}
						else{
							this.goHome()
						}
                        break
                    case 500:
						this.errorFlg = true
						this.pop(res.data.error[0])
                        break
                }
			}).catch()
		},



		register() {
			this.$router.push( {name: 'Register' })
		},
		pop(message) {
			this.popFlg = true // childの値を書き換え
			this.message = message // childの値を書き換え
		},
        togglePop() {
            this.popFlg = !this.popFlg
			if (this.errorFlg) this.$router.go({path: this.$router.currentRoute.path, force: true})
			if(!this.errorFlg) this.goHome()
        },
		goHome() {
			this.$router.push( {name: 'Home', params: { userData: this.userData } })
		},
	},
	created(){
		this.load()
	},
    watch: {
		loader () {
			const l = this.loader
			this[l] = !this[l]

			setTimeout(() => (this[l] = false), 1000)

			this.loader = null
		},
    },
}
</script>
<style scoped>
input:focus {
	border: 2px solid #5EADE4 !important;
	outline: none;
}
</style>